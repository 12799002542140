<template>
	<div class="roles-list list-view">
		<div class="list-header">
			<div class="col">
				<p>Current roles</p>
			</div>
		</div>
		<!-- end list-header -->
		<div class="grouping" v-for="(role, rank) in RolesWithLowerRankArray" v-bind:key="role.id" v-bind:data-rank="rank">
			<div class="col">
				<div class="heading">
					<div class="rank" v-bind:id="role.rank">
						<p>{{ role.rank }}</p>
					</div>
				</div>
				<!-- end heading -->
			</div>
			<div class="col span-3">
				<div class="roles items">
					<article class="role item grid-container">
						<div class="col">
							<p class="role-name item-name"><router-link :to="{path: `/admin/role-management/${role.slug}/edit`}">{{ role.name }}</router-link></p>
						</div>
						<!-- end col -->
						<div class="col last edit">
							<span class="edit" title="Edit role">
								<router-link :to="{path: `/admin/role-management/${role.slug}/edit`}">
									<i class="fad fa-cog"></i>
								</router-link>
							</span>
						</div>
						<!-- end col -->
					</article>
				</div>
			</div>
		</div>
		<!-- end grouping -->
		<!-- end roles -->
	</div>
	<!-- end roles-list -->
</template>
<script>
export default {
	data() {
		return {
			// rolesArray: [

			// ],
			RolesWithLowerRankArray: [

			],
		}
	},
    created(){
        // this.getAllRoles();
        this.getRolesWithLowerRank();
    },
    methods: {
        // async getAllRoles(){
        //     this.rolesArray = await this.$store.dispatch("roles/getAllRoles");
        // },
        async getRolesWithLowerRank(){
            this.RolesWithLowerRankArray = await this.$store.dispatch("roles/getAllRolesWithLowerRank");
        },
		// sortRoles() {
		// 	this.rolesArray.sort((a, b) => a.rank - b.rank);
		// }
    }
}
</script>
